import '../scss/hero.scss'

/**
 * Initializes DOMContentLoaded event listener to manipulate the hero title
 * and add event listeners to navigation links for carousel resizing.
 *
 * @listens DOMContentLoaded
 */
document.addEventListener('DOMContentLoaded', () => {
    // Replace the first word of the <h1> element with itself wrapped in a <span>
    const heroTitle = document.querySelector('.hero__title');
    heroTitle.innerHTML = heroTitle.textContent.replace(/^\P{L}*(\p{L}+)/u, '<span>$1</span>');

    // Add event listener to resize carousel when tab is shown.
    // (According to Ultimate 3D Carousel plugin we need to trigger manual resize in order for the carousel to be shown.)
    const navLinks = document.querySelectorAll('.hero__tabs .nav-link');
    navLinks.forEach(navLink => navLink.addEventListener('shown.bs.tab', () => {
        carouselResize();
    }));
});